import { Log } from "./log.js";

export class StringHelper {

    public static toKebabCase(s: String) {
        if (!s) {
            return null;
        }
        return s.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
    }

    public static toCamelCase(s: string) {
        return s.replace(/(?:^\w|[A-Z]|\b\w)/g,(word: string, index: any) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    public static format(s: string, ...args: any[]): string {
        if (typeof s != "string") {
            // To prevent send object as argument instead of string
            Log.w(`String expected in format() instead of ${typeof s}`);
            return null;
        }

        return s.replace(/{(\d+)}/g, (match: string, number) => {
            return typeof args[number] != "undefined" ? args[number] : match;
        });
    }

    public static hashCode(s: string): number {
        let h = 0;
        for (let i = 0; i < s.length; i++) {
            h = Math.imul(31, h) + s.charCodeAt(i) | 0;
        }
        return h;
    }

}